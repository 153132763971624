<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">网上展览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/list' }">展览管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加展览</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form label-position="top" :model="pageForm" :rules="pageRules" ref="pageRef" label-width="auto" class="page-form">
        <div class="form-content">
          <el-form-item label="展览名称" prop="title">
            <el-input v-model="pageForm.title"></el-input>
          </el-form-item>
          <el-form-item label="全局客服链接" prop="kf">
            <el-input v-model="pageForm.kf"></el-input>
          </el-form-item>
          <el-form-item label="微信分享图标" prop="wx_img">
            <upload :url="pageForm.wx_img" :filed="'wx_img'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
          </el-form-item>
          <el-form-item label="微信分享标题" prop="wx_title">
            <el-input v-model="pageForm.wx_title"></el-input>
          </el-form-item>
          <el-form-item label="微信分享内容" prop="wx_content">
            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="请输入内容" v-model="pageForm.wx_content"></el-input>
          </el-form-item>
          <el-form-item class="step-btn-area">
            <el-button @click="cancel" type="warning">取消</el-button>
            <el-button @click="submitForm" type="success">提 交</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/common/Upload'
export default {
  name: 'AddItem',
  components: {
    Upload
  },
  data () {
    return {
      pageForm: {
        title: '',
        kf: '',
        wx_img: '',
        wx_title: '',
        wx_content: ''
      },
      pageRules: {
        title: [
          { required: true, message: '请填写展览名称', trigger: 'blur' },
          { min: 1, max: 12, message: '长度在 1 到 12 个字符', trigger: 'blur' }
        ],
        kf: [
          { required: true, message: '请填写展览名称', trigger: 'blur' },
          { min: 1, max: 12, message: '长度在 1 到 12 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    cancel () {
      this.$router.push('/admin/apps/exhibition/list')
    },
    submitForm () {
      this.$refs.pageRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.post('/exhibition-item', this.pageForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.pageRef.resetFields()
            await this.$router.push('/admin/apps/exhibition/list')
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
  .page-content{
    width: 800px;
    margin-right: auto;
    margin-left: auto;
  }
</style>
